@import './styling/colors.scss';

body {
  color: #414042;
}

a::after {
  color: #414042;
}

button:disabled {
  cursor: default;
  opacity: 0.7;
}

a.button {
  text-decoration: none;
  display: block;
}

.button {
  border-radius: 16px;
  border: solid 1px rgba(112, 112, 112, 0);
  padding: 1rem 0 1rem 0;
}

.button-primary {
  background-color: $primary-color;
  color: $primary-background;
}

.form-input-group {
  width: 100%;
  margin: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;

  label {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: #000;
  }

  input {
    width: 100%;
    margin-top: 1.5rem;
    display: block;
    font-size: 1.3rem;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    padding-left: 1rem;
  }

  .button {
    width: 100%;
  }
}

form {
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
}

.input-with-button {
  position: relative;
  margin-bottom: 10px;
  button {
    position: absolute;
    right: 0px;
    top: 4px;
    border: none;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    outline: none;
    text-align: center;
    font-weight: bold;
    padding: 2px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ReactVirtualized__Grid.ReactVirtualized__List {
  outline: none;
}
